import React, { useMemo, forwardRef } from 'react';
import { Tooltip } from '@mui/material';

import { getProtocolLogo } from 'assets/protocolsLogos/protocolsLogos';
import { NetworkIcon, NextSvgIcon, NextSvgIconProps } from 'components';
import { styled } from 'core/styles';
import { ProductSlug, NetworkSlug } from 'domain/types';

type Props = {
  productSlug: ProductSlug;
  network?: NetworkSlug;
  tooltip?: string;
} & Omit<NextSvgIconProps, 'image'>;

export function ProtocolIcon(props: Props) {
  const { tooltip, ...rest } = props;

  return tooltip ? (
    <Tooltip title={tooltip} disableInteractive>
      <ProtocolIconContent {...rest} />
    </Tooltip>
  ) : (
    <ProtocolIconContent {...rest} />
  );
}

const ProtocolIconContent = forwardRef<HTMLDivElement, Omit<Props, 'tooltip'>>(
  function ProtocolIconContent(props, ref) {
    const { productSlug, network, alt, fontSize, ...rest } = props;
    const logoSrc = useMemo(() => getProtocolLogo(productSlug), [productSlug]);
    const iconProps = { alt, fontSize };

    return (
      logoSrc && (
        <Root ref={ref} {...rest}>
          <IconBox>
            <NextSvgIcon image={logoSrc} {...iconProps} />
          </IconBox>
          {network && (
            <IconBox>
              <NetworkIcon slug={network} {...iconProps} />
            </IconBox>
          )}
        </Root>
      )
    );
  },
);

const Root = styled('div')({
  display: 'flex',

  '&:empty': {
    display: 'none',
  },
});

const IconBox = styled('div')({
  display: 'flex',

  '& + &': {
    marginLeft: '-0.2em',
  },
});
